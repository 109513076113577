<template>
  <div>
    <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>数据统计</template>
      <template v-slot:secondMenu>CNZZ明细</template>
    </breadcrumb-nav>
    <el-card class="filter-container" shadow="never">
      <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline">
        <el-form-item label="站点ID" prop="site_id">
          <el-select v-model="listQuery.site_id" clearable placeholder="站点选择" style="width:120px">
            <el-option
              v-for="item in siteOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据类型" prop="req_type">
          <el-select v-model="listQuery.req_type" style="width:120px">
            <el-option
              v-for="item in dataOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期范围" prop="date">
          <el-date-picker
            v-model="listQuery.date"
            type="daterange"
            align="right"
            prop="date"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button @click="resetForm('listQuery')">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="cnzzDataList" border stripe :cell-style="changeCellStyle">
        <el-table-column label="站点ID" width="100px" prop="site_id"></el-table-column>
        <el-table-column :label="createTimeColumn" prop="create_time" width="170px" :formatter="formatDate">
        </el-table-column>
        <el-table-column label="拉起量" prop="up"></el-table-column>
        <el-table-column label="CNZZ量" prop="cnzz"></el-table-column>
        <el-table-column label="拉起率" width="80px" prop="up_rate" :formatter="formatUpRate">
          <!--<template slot-scope="scope">
            {{ scope.row.up_rate | formatFloat }}
          </template>-->
        </el-table-column>
        <el-table-column label="状态" width="60px" prop="status">
          <template slot-scope="scope">
            {{ scope.row.status | formatStatus }}
          </template>
        </el-table-column>
        <el-table-column label="单价" width="70px" prop="unit_price">
          <template slot-scope="scope">
            {{ scope.row.unit_price | formatFloat }}
          </template>
        </el-table-column>
        <!--<el-table-column label="结算IP(万)" width="90px" prop="settle_ip">
          <template slot-scope="scope">
            {{ scope.row.settle_ip | formatFloat }}
          </template>
        </el-table-column>-->
        <el-table-column label="成本" width="80px" prop="cost">
          <template slot-scope="scope">
            {{ scope.row.cost | formatFloat }}
          </template>
        </el-table-column>
        <el-table-column label="hw" width="80px" prop="hw" :formatter="formatHw">
          <!--<template slot-scope="scope">
            {{ scope.row | formatBrandHw }}
          </template>-->
        </el-table-column>
        <el-table-column label="oppo" width="80px" prop="oppo" :formatter="formatOppo">
        </el-table-column>
        <el-table-column label="vivo" width="80px" prop="vivo" :formatter="formatVivo">
        </el-table-column>
        <!--<el-table-column label="last-hw" width="95px" prop="last_hw">
        </el-table-column>
        <el-table-column label="last-oppo" width="95px" prop="last_oppo">
        </el-table-column>
        <el-table-column label="last-vivo" width="95px" prop="last_vivo">
        </el-table-column>-->
        <!--<el-table-column label="折算价格" prop="convert">
          <template slot-scope="scope">
            {{ scope.row.convert | formatFloat }}
          </template>
        </el-table-column>-->
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-sizes="[5,10,20]"
        :page-size="listQuery.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>

  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
// import * as echarts from 'echarts'

import {
  getCnzzSiteList,
  getCnzzDataList
} from '../../network/statistics'

export default {
  name: 'Cnzz',
  components: {
    BreadcrumbNav
  },
  filters: {
    formatStatus (value) {
      if (value === 1) {
        return '差'
      } else if (value === 2) {
        return '合格'
      } else if (value === 3) {
        return '良好'
      } else {
        return '优秀'
      }
    },
    formatFloat (value) {
      return value.toFixed(2)
    }
  },
  data () {
    return {
      // 查询参数
      listQuery: {
        site_id: '',
        date: '',
        page: 1,
        size: 10,
        req_type: 0
      },
      dataOptions: [
        {
          label: '明细',
          value: 0
        },
        {
          label: '日结',
          value: 1
        }
      ],
      createTimeColumn: '创建时间',
      curReqType: 0,
      // 站点列表
      siteOptions: [],
      // cnzz统计数据
      cnzzDataList: [],
      // 总数据条数
      total: 0
    }
  },
  mounted () {
    this.getSiteList()
    this.getDataList(this.listQuery)
  },
  methods: {
    // 根据测试结果动态调整单元格样式，成功-绿色，失败-红色，不支持-黄色
    changeCellStyle ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      let cellStyle
      if (column.label === '状态') {
        switch (row.status) {
          case 1:
            cellStyle = 'color:#DC143C'
            break
          case 2:
            cellStyle = 'color:#EE82EE'
            break
          case 3:
            cellStyle = 'color:#4169E1'
            break
          case 4:
            cellStyle = 'color:#008000'
            break
          default:
            cellStyle = ''
        }
        return cellStyle
      } else if (column.label === 'hw') {
        if (row.hw > row.last_hw) {
          cellStyle = 'color:#008000'
        } else if (row.hw < row.last_hw) {
          cellStyle = 'color:#DC143C'
        } else {
          cellStyle = 'color:#4169E1'
        }
        return cellStyle
      } else if (column.label === 'oppo') {
        if (row.oppo > row.last_oppo) {
          cellStyle = 'color:#008000'
        } else if (row.oppo < row.last_oppo) {
          cellStyle = 'color:#DC143C'
        } else {
          cellStyle = 'color:#4169E1'
        }
        return cellStyle
      } else if (column.label === 'vivo') {
        if (row.vivo > row.last_vivo) {
          cellStyle = 'color:#008000'
        } else if (row.vivo < row.last_vivo) {
          cellStyle = 'color:#DC143C'
        } else {
          cellStyle = 'color:#4169E1'
        }
        return cellStyle
      } else if (column.label === '拉起率') {
        if (row.up_rate > row.last_up) {
          cellStyle = 'color:#008000'
        } else if (row.up_rate < row.last_up) {
          cellStyle = 'color:#DC143C'
        } else {
          cellStyle = 'color:#4169E1'
        }
        return cellStyle
      }
    },
    formatUpRate (row, column) {
      if (!row.last_up) {
        return row.up_rate.toFixed(2) + ''
      }
      if (row.up_rate > row.last_up) {
        return row.up_rate.toFixed(2) + ' ↑'
      } else if (row.up_rate < row.last_up) {
        return row.up_rate.toFixed(2) + ' ↓'
      } else {
        return row.up_rate.toFixed(2) + ''
      }
    },
    formatHw (row, column) {
      if (!row.hw) {
        return ''
      }
      if (row.hw > row.last_hw) {
        return row.hw.toFixed(2) + ' ↑'
      } else if (row.hw < row.last_hw) {
        return row.hw.toFixed(2) + ' ↓'
      } else {
        return row.hw.toFixed(2) + ''
      }
    },
    formatOppo (row, column) {
      if (!row.oppo) {
        return ''
      }
      if (row.oppo > row.last_oppo) {
        return row.oppo.toFixed(2) + ' ↑'
      } else if (row.oppo < row.last_oppo) {
        return row.oppo.toFixed(2) + ' ↓'
      } else {
        return row.oppo.toFixed(2) + ''
      }
    },
    formatVivo (row, column) {
      if (!row.vivo) {
        return ''
      }
      if (row.vivo > row.last_vivo) {
        return row.vivo.toFixed(2) + ' ↑'
      } else if (row.vivo < row.last_vivo) {
        return row.vivo.toFixed(2) + ' ↓'
      } else {
        return row.vivo.toFixed(2) + ''
      }
    },
    formatDate (row, column) {
      const value = row.create_time
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      if (this.curReqType === 1) {
        return `${year}-${month}-${day}`
      } else {
        const hour = (date.getHours() + '').padStart(2, '0')
        const minute = (date.getMinutes() + '').padStart(2, '0')
        const second = (date.getSeconds() + '').padStart(2, '0')
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`
      }
    },
    setCreateTimeColumn (reqType) {
      if (reqType === 1) {
        this.createTimeColumn = '创建日期'
      } else {
        this.createTimeColumn = '创建时间'
      }
      console.log('reqType:', reqType, ';table column:', this.createTimeColumn)
    },
    onSubmit () {
      this.setCreateTimeColumn(this.listQuery.req_type)
      console.log('submit!', this.listQuery)
      this.getDataList(this.listQuery)
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    getSiteList () {
      getCnzzSiteList({
        page: 1,
        size: 100
      }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('cnzz站点列表获取失败', 'error')
        }
        for (let i = 0; i < result.data.data.length; i++) {
          this.siteOptions.push({
            label: result.data.data[i].site_id,
            value: result.data.data[i].site_id
          })
        }
        console.log('site options:', this.siteOptions)
      })
    },
    getDataList (params) {
      console.log('list params:', params)
      const query = {
        page: params.page,
        size: params.size,
        site_id: params.site_id,
        req_type: params.req_type
      }
      if (params.date !== null && params.date !== undefined && params.date.length > 1) {
        query.from = params.date[0]
        query.to = params.date[1]
      }
      getCnzzDataList(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('cnzz站点列表获取失败', 'error')
        }
        this.curReqType = this.listQuery.req_type
        this.cnzzDataList = result.data.data
        this.total = result.data.total
        console.log('cnzz data:', this.cnzzDataList)
      })
    },
    // 监听size改变
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getDataList(this.listQuery)
    },
    // 监听page的改变
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getDataList(this.listQuery)
    }
  }
}
</script>

<style scoped>

</style>
